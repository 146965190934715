import {
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import axiosInstance from "../../Api/axios.instance";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const MENU_ITEMS = [
  {
    title: "Clusters",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/clusters",
    icon: "GpsFixedIcon",
  },
  {
    title: "Subjects & Classes",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/class-subjects",
    icon: "SubjectIcon",
  },
  {
    title: "Staff",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/staff",
    icon: "PersonOutlineIcon",
  },
  {
    title: "Students",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/students",
    icon: "PeopleIcon",
  },
  {
    title: "Parent Interview",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/parent-interview",
    icon: "PodcastsIcon",
  },
  {
    title: "Fee Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/fee-management",
    icon: "CreditCardIcon",
  },
  {
    title: "Student Attendance",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/attendance",
    icon: "HowToRegIcon",
  },
  {
    title: "Staff Attendance",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/staff-attendance",
    icon: "HowToRegIcon",
  },
  {
    title: "User Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/user-management",
    icon: "ManageAccountsIcon",
  },
  {
    title: "Test Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/test-management",
    icon: "NoteAltIcon",
  },
  {
    title: "Co-Curricular",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/co-curricular",
    icon: "AdminPanelSettingsIcon",
  },
  {
    title: "CAP",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/CAP",
    icon: "EventIcon",
  },
  {
    title: "PTM",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/PTM",
    icon: "MeetingRoomIcon",
  },
  {
    title: "Syllabus Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/syllabus",
    icon: "AssignmentIcon",
  },
  {
    title: "School Observation",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/fixed-assets/fixed-asset-observation",
    icon: "HomeRepairServiceIcon",
  },
  {
    title: "Variable Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/variable-management",
    icon: "CategoryIcon",
  },
  {
    title: "Library Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/library",
    icon: "LocalLibraryIcon",
  },
  {
    title: "Task Management",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/library",
    icon: "LocalTaskIcon",
  },
  {
    title: "Workshop",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/workshop",
    icon: "HomeRepairServiceIcon",
  },
  {
    title: "Class Observation",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/class-observation",
    icon: "ContentPasteSearchIcon",
  },
  {
    title: "School Record",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/school-record",
    icon: "AssignmentTurnedInIcon",
  },
  {
    title: "School Assembly",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/school-assembly",
    icon: "CastForEducationIcon",
  },
  {
    title: "Weekly Assembly Record",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/weekly-assembly-record",
    icon: "DvrIcon",
  },
  {
    title: "Fixed Assets",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/fixed-assets",
    icon: "ListAltIcon",
  },
  {
    Reports: [
      {
        title: "Fee Collection V1",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/fee-collection-v1",
        icon: "ReportIcon",
      },
      {
        title: "Daily Entries Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/daily-entries",
        icon: "ReportIcon",
      },
      {
        title: "Class Wise Students",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/class-wise-students",
        icon: "ReportIcon",
      },
      {
        title: "Dropouts",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/dropout-students",
        icon: "ReportIcon",
      },
      {
        title: "Dropouts Session Wise",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/dropouts-session-wise",
        icon: "ReportIcon",
      },
      {
        title: "Orphans",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/orphans-strength",
        icon: "ReportIcon",
      },
      {
        title: "Male/Female Ratio",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/male-female-ratio",
        icon: "ReportIcon",
      },
      {
        title: "New Admissions School Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/new-admissions-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "New Admissions Cluster Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/new-admissions-cluster-wise",
        icon: "ReportIcon",
      },
      {
        title: "Dropouts School Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/dropout-student-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "Passouts School Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/passout-student-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "Subject/Variation Result Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/student-test-results-report",
        icon: "ReportIcon",
      },
      {
        title: "All Subject Result Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/student-all-subject-test-results-report",
        icon: "ReportIcon",
      },
      {
        title: "Grade Wise Cluster Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/student-by-grade-cluster-wise-report",
        icon: "ReportIcon",
      },
      {
        title: "Exam Detail Cluster/School Wise Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/student-by-grade-school-class-wise-report",
        icon: "ReportIcon",
      },
      {
        title: "Teacher Attendance Summary",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/teacher-attedance-summary",
        icon: "ReportIcon",
      },
      {
        title: "Test Result Attendance",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/testresult-present-absent-count-schoolwise",
        icon: "ReportIcon",
      },
      {
        title: "Active Student Details",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/active-student-report-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "Active Student Attendance Stats",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/student-attendance-stats-date-range-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "List of Pending Class Teacher Observation",
          create: false,
          read: false,
          update: false,
          delete: false,
          route:"/reports/list-of-observation-not-done-class-teacher-observation-list-periodic",
          icon: "ReportIcon",
      },
      {
        title: "Class Teacher Observation Matrix",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/quit-student-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "Quit School Report",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/quit-student-school-wise",
        icon: "ReportIcon",
      },
      {
        title: "Asset Physical Condition",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/fixed-assets-condition",
        icon: "ReportIcon",
      },
      {
        title: "Paid Fee Summary School Wise",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/school-wise-fee-collection-report",
        icon: "ReportIcon",
      },
      {
        title: "Unpaid Fee List of Students",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/upaid-fee-school-wise-report",
        icon: "ReportIcon",
      },
      {
        title: "All Tasks",
        create: false,
        read: false,
        update: false,
        delete: false,
        route: "/reports/all-task",
        icon: "ReportIcon",
      },
    ],
  },
];

const REPORT_ITEMS = [
  {
    title: "Fee Collection V1",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/fee-collection-v1",
    icon: "ReportIcon",
  },
  {
    title: "Daily Entries Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/daily-entries",
    icon: "ReportIcon",
  },
  {
    title: "Class Wise Students",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/class-wise-students",
    icon: "ReportIcon",
  },
  {
    title: "Dropouts",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/dropout-students",
    icon: "ReportIcon",
  },
  {
    title: "Dropouts Session Wise",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/dropouts-session-wise",
    icon: "ReportIcon",
  },
  {
    title: "Orphans",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/orphans-strength",
    icon: "ReportIcon",
  },
  {
    title: "Male/Female Ratio",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/male-female-ratio",
    icon: "ReportIcon",
  },
  {
    title: "New Admissions School Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/new-admissions-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "New Admissions Cluster Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/new-admissions-cluster-wise",
    icon: "ReportIcon",
  },
  {
    title: "Dropouts School Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/dropout-student-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "Passouts School Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/passout-student-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "Subject/Variation Result Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/student-test-results-report",
    icon: "ReportIcon",
  },
  {
    title: "All Subject Result Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/student-all-subject-test-results-report",
    icon: "ReportIcon",
  },
  {
    title: "Grade Wise Cluster Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/student-by-grade-cluster-wise-report",
    icon: "ReportIcon",
  },
  {
    title: "Exam Detail Cluster/School Wise Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/student-by-grade-school-class-wise-report",
    icon: "ReportIcon",
  },
  {
    title: "Teacher Attendance Summary",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/teacher-attedance-summary",
    icon: "ReportIcon",
  },
  {
    title: "Test Result Attendance",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/testresult-present-absent-count-schoolwise",
    icon: "ReportIcon",
  },
  {
    title: "Active Student Details",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/active-student-report-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "Active Student Attendance Stats",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/student-attendance-stats-date-range-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "List of Pending Class Teacher Observation",
      create: false,
      read: false,
      update: false,
      delete: false,
      route:"/reports/list-of-observation-not-done-class-teacher-observation-list-periodic",
      icon: "ReportIcon",
  },
  {
    title: "Class Teacher Observation Matrix",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/quit-student-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "Quit School Report",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/quit-student-school-wise",
    icon: "ReportIcon",
  },
  {
    title: "Asset Physical Condition",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/fixed-assets-condition",
    icon: "ReportIcon",
  },
  {
    title: "Paid Fee Summary School Wise",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/school-wise-fee-collection-report",
    icon: "ReportIcon",
  },
  {
    title: "Unpaid Fee List of Students",
    create: false,
    read: false,
    update: false,
    delete: false,
    route: "/reports/upaid-fee-school-wise-report",
    icon: "ReportIcon",
  },
];

export default function AddUserRoles() {
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [menus, setMenus] = useState([...MENU_ITEMS]);
  const [reports, setReports] = useState([...REPORT_ITEMS]);
  const [roleName, setRoleName] = useState("");
  const [accesLevel, setAccesLevel] = useState("");

  const _resetForm = () => {
    console.log("Menus after submition", menus);
    setRoleName("");
    setAccesLevel("");
  };

  const handleMenusChange = (e, key) => {
    const { name, value } = e?.target;
    const newMenus = [...menus];
    newMenus[key][name] = e.target.checked;
    setMenus(newMenus);
  };

  const handleReportsChange = (e, key) => {
    const { name, value } = e?.target;
    const newReports = [...reports];
    newReports[key][name] = e.target.checked;
    setReports(newReports);
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const handleAccessLevelChange = (e) => {
    setAccesLevel(e.target.value);
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };

  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("menus", menus);
    console.log("roleName", roleName);
    console.log("accesLevel", accesLevel);
    const formData = {
      roleName: roleName,
      status: true,
      permission: {
        accessLevel: accesLevel,
        pages: menus,
      },
    };
    console.log("formData", formData);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.post("/roles", formData);
      console.log("Response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        setSnackMessage(data.message);
        setSnackBar(true);
        _resetForm();
        setMenus([...MENU_ITEMS]);
        setReports([...REPORT_ITEMS]);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  return (
    <>
      <Box flexGrow={1} onSubmit={_onSubmit} component="form">
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="h5" component="h4">
              Roles
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider sx={{ mt: 1, mb: 2 }} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mb: 2 }}>
            <TextField
              label="Role Name"
              variant="outlined"
              size="small"
              sx={{ display: "flex", flex: 1 }}
              name="roleName"
              value={roleName}
              // value={element.IndexID}
              onChange={handleRoleNameChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="standard-select-currency"
              select
              label="Select Access Level"
              sx={{ display: "flex", flex: 1 }}
              // value={formik.values.SchoolID}
              variant="outlined"
              name="accesLevel"
              value={accesLevel}
              required
              onChange={handleAccessLevelChange}
              // error={formik.touched.SchoolID && Boolean(formik.errors.SchoolID)}
              // helperText={formik.touched.SchoolID && formik.errors.SchoolID}
              size="small"
            >
              <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
              <MenuItem value={"CLUSTER"}>CLUSTER</MenuItem>
              <MenuItem value={"SCHOOL"}>SCHOOL</MenuItem>
              <MenuItem value={"STAFF"}>STAFF</MenuItem>
              {/* <MenuItem value={"TEACHER"}>TEACHER</MenuItem> */}
            </TextField>
          </Grid>
        </Grid>
        {menus.map((menu, index) => {
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" component="h4">
                      {menu.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.create}
                      name="create"
                      control={<Checkbox checked={menu.create} />}
                      label="CREATE"
                      labelPlacement="CREATE"
                      onChange={(e) => handleMenusChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.read}
                      name="read"
                      control={<Checkbox checked={menu.read} />}
                      label="READ"
                      labelPlacement="READ"
                      onChange={(e) => handleMenusChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.update}
                      name="update"
                      control={<Checkbox checked={menu.update} />}
                      label="UPDATE"
                      labelPlacement="UPDATE"
                      onChange={(e) => handleMenusChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.delete}
                      name="delete"
                      control={<Checkbox checked={menu.delete} />}
                      label="DELETE"
                      labelPlacement="DELETE"
                      onChange={(e) => handleMenusChange(e, index)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
            </Grid>
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Divider sx={{ mt: 1, mb: 2 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography variant="h5" component="h4">
              Reports
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider sx={{ mt: 1, mb: 2 }} />
          </Grid>
          <Grid item xs={12} md={12}>
            <Divider sx={{ mt: 1, mb: 2 }} />
          </Grid>
        </Grid>
        {reports.map((menu, index) => {
          return (
            <Grid container spacing={2} key={index}>
              <Grid item xs={12} md={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={12}>
                    <Typography variant="h6" component="h4">
                      {menu.title}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.create}
                      name="create"
                      control={<Checkbox checked={menu.create} />}
                      label="CREATE"
                      labelPlacement="CREATE"
                      onChange={(e) => handleReportsChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.read}
                      name="read"
                      control={<Checkbox checked={menu.read} />}
                      label="READ"
                      labelPlacement="READ"
                      onChange={(e) => handleReportsChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.update}
                      name="update"
                      control={<Checkbox checked={menu.update} />}
                      label="UPDATE"
                      labelPlacement="UPDATE"
                      onChange={(e) => handleReportsChange(e, index)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControlLabel
                      value={menu.delete}
                      name="delete"
                      control={<Checkbox checked={menu.delete} />}
                      label="DELETE"
                      labelPlacement="DELETE"
                      onChange={(e) => handleReportsChange(e, index)}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12}>
                <Divider sx={{ mt: 1, mb: 2 }} />
              </Grid>
            </Grid>
          );
        })}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth>
              <LoadingButton
                loading={loading}
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                Create Role
              </LoadingButton>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
