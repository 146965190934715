import React, { useEffect, lazy, Suspense } from "react";
import { Routes as DomRoutes, Route } from "react-router-dom";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import App from "../App";
import {
  ClassSubject,
  ClusterDetails,
  Clusters,
  ClustersWrapper,
  Dashboard,
  AdminDashboard,
  Login,
  Staff,
  Students,
  StudentsWrapper,
  Users,
} from "../Pages";
// const ClassSubject = lazy(() => import("../Pages"));
// const ClusterDetails = lazy(() => import("../Pages"));
// const Clusters = lazy(() => import("../Pages"));
// const ClustersWrapper = lazy(() => import("../Pages"));
// const Dashboard = lazy(() => import("../Pages"));
// const AdminDashboard = lazy(() => import("../Pages"));
// const Login = lazy(() => import("../Pages"));
// const Staff = lazy(() => import("../Pages"));
// const Students = lazy(() => import("../Pages"));
// const StudentsWrapper = lazy(() => import("../Pages"));
// const Users = lazy(() => import("../Pages"));
const Attendance = lazy(() => import("../Pages/Attendance"));
// import Attendance from "../Pages/Attendance";
const Fee = lazy(() => import("../Pages/Fee"));
const Transactions = lazy(() => import("../Pages/Library/Transactions"));
const Library = lazy(() => import("../Pages/Library"));
const PasswordRequest = lazy(() => import("../Pages/PasswordRequest"));
const SchoolDetails = lazy(() => import("../Pages/Schools/SchoolDetail"));
const StaffDeatils = lazy(() => import("../Pages/Staff/Details"));
const AddStudents = lazy(() => import("../Pages/Students/AddStudents"));
const StudentDetails = lazy(() => import("../Pages/Students/Details"));
const Syllabus = lazy(() => import("../Pages/Syllabus"));
const Test = lazy(() => import("../Pages/Test"));
const UserDetails = lazy(() => import("../Pages/Users/Details"));
const Variables = lazy(() => import("../Pages/Variables"));
const SyllabusAddOrView = lazy(() =>
  import("../Pages/Syllabus/SyllabusAddOrView")
);
const SyllabusWrapper = lazy(() => import("../Pages/Syllabus/SyllabusWrapper"));
const Rollback = lazy(() => import("../Pages/Rollback/Rollback"));
// import Rollback from "../Pages/Rollback/Rollback";
const LibraryWrapper = lazy(() => import("../Pages/Library/LibraryWrapper"));
const WorkShopWrapper = lazy(() => import("../Pages/WorkShop/WorkShopWrapper"));
const Participant = lazy(() => import("../Pages/WorkShop/Participant"));
const WorkShop = lazy(() => import("../Pages/WorkShop"));
const ClassObservation = lazy(() => import("../Pages/ClassObservation"));
const SchoolRecord = lazy(() => import("../Pages/SchoolRecord"));
const ClassObservationForm = lazy(() =>
  import("../Pages/ClassObservation/ClassObservationForm")
);
const StaffAttendance = lazy(() => import("../Pages/StaffAttendance"));
const SchoolAssembly = lazy(() => import("../Pages/SchoolAssembly"));
const WeeklyAssemblyRecord = lazy(() =>
  import("../Pages/WeeklyAssemblyRecord")
);
const ReportsWrapper = lazy(() => import("../Pages/Reports/ReportsWrapper"));
const DailyEntries = lazy(() => import("../Pages/Reports/DailyEntries"));
const FeeCollectionV1 = lazy(() => import("../Pages/Reports/FeeCollectionV1"));
const ParentInterview = lazy(() => import("../Pages/ParentInterview"));
const TestWrapper = lazy(() => import("../Pages/Test/TestWrapper"));
const TestResults = lazy(() => import("../Pages/Test/TestResults"));
const CoCurricular = lazy(() => import("../Pages/CoCurricular"));
const Cap = lazy(() => import("../Pages/Cap"));
const Ptm = lazy(() => import("../Pages/Ptm"));
const CoCurricularWrapper = lazy(() =>
  import("../Pages/CoCurricular/CoCurricularWrapper")
);
const StaffPerformance = lazy(() =>
  import("../Pages/CoCurricular/StaffPerformance")
);
const StudentPerformance = lazy(() =>
  import("../Pages/CoCurricular/StudentPerformance")
);
const Infrastructure = lazy(() => import("../Pages/Infrastructure"));
const FixedAssets = lazy(() => import("../Pages/FixedAssets"));
const PromoteStudents = lazy(() => import("../Pages/PromoteStudents"));
const AssetWrapper = lazy(() => import("../Pages/FixedAssets/AssetWrapper"));
const AssetTransaction = lazy(() =>
  import("../Pages/FixedAssets/AssetTransaction")
);
const ClassWiseStudents = lazy(() =>
  import("../Pages/Reports/ClassWiseStudents")
);
const OrphansStrength = lazy(() => import("../Pages/Reports/OrphansStrength"));
const MaleAndFemaleRatio = lazy(() =>
  import("../Pages/Reports/MaleAndFemaleRatio")
);
const DropoutStudents = lazy(() => import("../Pages/Reports/DropoutStudents"));
const StudentWiseMarkSheet = lazy(() =>
  import("../Pages/Reports/StudentWiseMarkSheet")
);
const NewAdmissionsSchoolWise = lazy(() =>
  import("../Pages/Reports/NewAdmissionsSchoolWise")
);
const NewAdmissionsClusterWise = lazy(() =>
  import("../Pages/Reports/NewAdmissionsClusterWise")
);
const DropoutStudentsSchoolWise = lazy(() =>
  import("../Pages/Reports/DropoutStudentsSchoolWise")
);
const PassoutStudentsSchoolWise = lazy(() =>
  import("../Pages/Reports/PassoutStudentsSchoolWise")
);
const StudentTestResultsReport = lazy(() =>
  import("../Pages/Reports/StudentTestResultsReport")
);
const AllSubjectTestResults = lazy(() =>
  import("../Pages/Reports/AllSubjectTestResults")
);
const StudentsByGradeClusterWise = lazy(() =>
  import("../Pages/Reports/StudentsByGradeClusterWise")
);
const StudentsByGradeSchoolClassWise = lazy(() =>
  import("../Pages/Reports/StudentsByGradeSchoolClassWise")
);
const AssetObservation = lazy(() =>
  import("../Pages/FixedAssets/AssetObservation")
);
const FeeVoucher = lazy(() => import("../Pages/Fee/FeeVoucher"));
const StudentsDropoutsSessionWise = lazy(() =>
  import("../Pages/Reports/StudentsDropoutsSessionWise")
);
const TeacherAttendaceSummary = lazy(() =>
  import("../Pages/Reports/TeacherAttendanceSummary")
);
const TestResultAttendance = lazy(() =>
  import("../Pages/Reports/ClassWiseSubjectStudentPresentAbsentExam")
);
const ActiveStudentDetails = lazy(() =>
  import("../Pages/Reports/ActiveStudentDetails")
);
const ActiveStudentAttendanceStats = lazy(() =>
  import("../Pages/Reports/ActiveStudentAttendanceStats")
);
const ListOfTeachersObservationNotDone = lazy(() =>
  import("../Pages/Reports/ListOfTeacherObservationNotDone")
);
const PIVOTResultTeacherClassObservation = lazy(() =>
  import("../Pages/Reports/TeacherClassObservationResultPIVOT")
);
const QuitStudentsSchoolWise = lazy(() =>
  import("../Pages/Reports/QuitStudentsSchoolWise")
);
const AssetPhysicalCondition = lazy(() =>
  import("../Pages/Reports/AssetPhysicalCondition")
);
const CAPStats = lazy(() => import("../Pages/AdminReports/CAPStats"));
const PTMStats = lazy(() => import("../Pages/AdminReports/PTMStats"));
const PaidFeeSummary = lazy(() =>
  import("../Pages/AdminReports/PaidFeeSummaryAdmin")
);
const ReactPivotTable = lazy(() => import("../Pages/Reports/ReactPivotTable"));
const PaidFeeSummarySchool = lazy(() =>
  import("../Pages/Reports/PaidFeeSummarySchool")
);
const UnpaidFeeSummarySchool = lazy(() =>
  import("../Pages/Reports/UnPaidFeeSummarySchool")
);
const SchoolObservationSubjectWiseTeacherWise = lazy(() =>
  import("../Pages/Reports/SchoolObservationSubjectWiseTeacherWise")
);
const SchoolObservationTeacherWiseNotDone = lazy(() =>
  import("../Pages/Reports/SchoolObservationTeacherWiseNotDone")
);
const SchoolObservationSubjectWiseNotDone = lazy(() =>
  import("../Pages/Reports/SchoolObservationSubjectWiseNotDone")
);
const SchoolObservationAdmin = lazy(() =>
  import("../Pages/AdminReports/SchoolObservationAdmin")
);
const TasksReport = lazy(() => import("../Pages/AdminReports/TasksReport"));
const CoCurricularSchoolWiseYearWise = lazy(() =>
  import("../Pages/AdminReports/CoCurricularSchoolWiseYearWise")
);
const SubjectWiseGradingForAllClusters = lazy(() =>
  import("../Pages/AdminReports/SubjectWiseGradingForAllClusters")
);
const SubjectWiseGradingForAllSchool = lazy(() =>
  import("../Pages/AdminReports/SubjectWiseGradingForAllSchool")
);
const SubjectWiseGradingForAllSchoolsOfSelectedCluster = lazy(() =>
  import(
    "../Pages/AdminReports/SubjectWiseGradingForAllSchoolsOfSelectedCluster"
  )
);
const SubjectWiseGradingForAllClassesOfSelectedCluster = lazy(() =>
  import(
    "../Pages/AdminReports/SubjectWiseGradingForAllClassesOfSelectedCluster"
  )
);
const SubjectWiseGradingForAllClassesOfSelectedClusterAndSchool = lazy(() =>
  import(
    "../Pages/AdminReports/SubjectWiseGradingForAllClassesOfSelectedClusterAndSchool"
  )
);
const TaskWrapper = lazy(() => import("../Pages/TaskManagement/TaskWrapper"));
const Task = lazy(() => import("../Pages/TaskManagement"));
const AddObservation = lazy(() =>
  import("../Pages/FixedAssets/AddObservation")
);
const UserTask = lazy(() => import("../Pages/UserTask"));

export default function Routes(props) {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <DomRoutes>
        <Route path="/" element={<App />}>
          {window.sessionStorage.getItem("workStation") == "admin" ? (
            <Route index element={<AdminDashboard />} />
          ) : (
            <Route index element={<Dashboard />} />
          )}{" "}
          <Route path="/UserTask" element={<UserTask />} />
          <Route path="/clusters" element={<ClustersWrapper />}>
            <Route index element={<Clusters />} />
            <Route path="details/:id" element={<ClusterDetails />} />
            <Route path="school/:id" element={<SchoolDetails />} />
          </Route>
          <Route path="/class-subjects" element={<ClassSubject />} />
          <Route index path="/staff" element={<Staff />} />
          <Route path="staffDetails/:id" element={<StaffDeatils />} />
          <Route index path="/user-management" element={<Users />} />
          <Route path="/test-management" element={<TestWrapper />}>
            <Route index element={<Test />} />
            <Route path="results" element={<TestResults />} />
          </Route>
          <Route path="/syllabus" element={<SyllabusWrapper />}>
            <Route index element={<Syllabus />} />
            <Route path="add" element={<SyllabusAddOrView />} />
          </Route>
          <Route index path="/attendance" element={<Attendance />} />
          <Route index path="/staff-attendance" element={<StaffAttendance />} />
          <Route index path="/school-assembly" element={<SchoolAssembly />} />
          <Route index path="/fee-management" element={<FeeVoucher />} />
          <Route index path="/fee-management-old" element={<Fee />} />
          <Route index path="/variable-management" element={<Variables />} />
          <Route path="/library" element={<LibraryWrapper />}>
            <Route index element={<Library />} />
            <Route path="transaction" element={<Transactions />} />
          </Route>
          <Route path="/task" element={<TaskWrapper />}>
            <Route index element={<Task />} />
          </Route>
          <Route path="/workshop" element={<WorkShopWrapper />}>
            <Route index element={<WorkShop />} />
            <Route path="participant" element={<Participant />} />
          </Route>
          <Route path="user-management/:id" element={<UserDetails />} />
          <Route
            path="/weekly-assembly-record"
            element={<WeeklyAssemblyRecord />}
          />
          {/*<Route
          path="/class-observation-form"
          element={<ClassObservationForm />}
        />*/}
          <Route path="/class-observation" element={<ClassObservationForm />} />
          <Route path="/school-record" element={<SchoolRecord />} />
          <Route path="/parent-interview" element={<ParentInterview />} />
          <Route
            path="/change-password-request"
            element={<PasswordRequest />}
          />
          <Route path="/infrastructure" element={<Infrastructure />} />
          <Route path="/co-curricular" element={<CoCurricularWrapper />}>
            <Route index element={<CoCurricular />} />
            {/*<Route path="staff-performance/:id" element={<AddStudents />} />*/}
            <Route path="staff-performance" element={<StaffPerformance />} />
            <Route
              path="student-performance"
              element={<StudentPerformance />}
            />
          </Route>
          <Route path="/fixed-assets" element={<AssetWrapper />}>
            <Route index element={<FixedAssets />} />]
            <Route
              path="fixed-asset-transaction"
              element={<AssetTransaction />}
            />
            <Route
              path="fixed-asset-observation"
              element={<AssetObservation />}
            />
          </Route>
          <Route path="add-observation" element={<AddObservation />} />
          <Route path="/CAP" element={<Cap />} />
          <Route path="/promote-students" element={<PromoteStudents />} />
          <Route path="/PTM" element={<Ptm />} />
          <Route path="/Rollback" element={<Rollback />} />
          <Route path="/students" element={<StudentsWrapper />}>
            <Route index element={<Students />} />
            <Route path="add" element={<AddStudents />} />
            <Route path="details/:id" element={<StudentDetails />} />
            <Route path="edit/:id" element={<AddStudents />} />
            <Route path="dropout/:id" element={<AddStudents />} />
          </Route>
          <Route path="/reports" element={<ReportsWrapper />}>
            <Route
              path="student-by-grade-school-class-wise-report"
              element={<StudentsByGradeSchoolClassWise />}
            />
            <Route path="all-task" element={<TasksReport />} />
            <Route
              path="student-by-grade-cluster-wise-report"
              element={<StudentsByGradeClusterWise />}
            />
            <Route
              path="student-all-subject-test-results-report"
              element={<AllSubjectTestResults />}
            />
            <Route
              path="student-test-results-report"
              element={<StudentTestResultsReport />}
            />
            <Route
              path="passout-student-school-wise"
              element={<PassoutStudentsSchoolWise />}
            />
            <Route
              path="dropout-student-school-wise"
              element={<DropoutStudentsSchoolWise />}
            />
            <Route
              path="new-admissions-school-wise"
              element={<NewAdmissionsSchoolWise />}
            />
            <Route
              path="new-admissions-cluster-wise"
              element={<NewAdmissionsClusterWise />}
            />
            <Route
              path="dropouts-session-wise"
              element={<StudentsDropoutsSessionWise />}
            />
            <Route
              path="teacher-attendance-summary"
              element={<TeacherAttendaceSummary />}
            />
            <Route
              path="testresult-present-absent-count-schoolwise"
              element={<TestResultAttendance />}
            />
            <Route
              path="active-student-report-school-wise"
              element={<ActiveStudentDetails />}
            />
            <Route
              path="student-attendance-stats-date-range-school-wise"
              element={<ActiveStudentAttendanceStats />}
            />
            <Route
              path="list-of-observation-not-done-class-teacher-observation-list-periodic"
              element={<ListOfTeachersObservationNotDone />}
            />
            <Route
              path="pivot-result-teacher-class-observation-period"
              element={<PIVOTResultTeacherClassObservation />}
            />
            <Route
              path="quit-student-school-wise"
              element={<QuitStudentsSchoolWise />}
            />
            <Route path="react-pivot-table" element={<ReactPivotTable />} />
            <Route path="mark-sheet" element={<StudentWiseMarkSheet />} />
            <Route path="dropout-students" element={<DropoutStudents />} />
            <Route
              path="class-observation-subjectwise-teacherwise"
              element={<SchoolObservationSubjectWiseTeacherWise />}
            />
            <Route
              path="class-observation-teacherwise-notDone"
              element={<SchoolObservationTeacherWiseNotDone />}
            />
            <Route
              path="class-observation-subjectwise-notDone"
              element={<SchoolObservationSubjectWiseNotDone />}
            />
            <Route path="male-female-ratio" element={<MaleAndFemaleRatio />} />
            <Route path="orphans-strength" element={<OrphansStrength />} />
            <Route path="class-wise-students" element={<ClassWiseStudents />} />
            <Route path="daily-entries" element={<DailyEntries />} />
            <Route path="fee-collection-v1" element={<FeeCollectionV1 />} />
            <Route
              path="fixed-assets-condition"
              element={<AssetPhysicalCondition />}
            />
            <Route
              path="upaid-fee-school-wise-report"
              element={<UnpaidFeeSummarySchool />}
            />
            <Route
              path="school-wise-fee-collection-report"
              element={<PaidFeeSummarySchool />}
            />
          </Route>
          <Route index path="/capstats" element={<CAPStats />} />
          <Route index path="/ptmstats" element={<PTMStats />} />
          <Route index path="/paid-fee-summary" element={<PaidFeeSummary />} />
          <Route
            index
            path="/school-observation-schoolwise-monthwise"
            element={<SchoolObservationAdmin />}
          />
          <Route
            index
            path="/cocurricular-schoolwise-yearwise-perfomance"
            element={<CoCurricularSchoolWiseYearWise />}
          />
          <Route
            index
            path="/get-subject-wise-grading-for-all-clusters"
            element={<SubjectWiseGradingForAllClusters />}
          />
          <Route
            index
            path="/get-subject-wise-grading-for-all-school-of-selected-cluster"
            element={<SubjectWiseGradingForAllSchoolsOfSelectedCluster />}
          />
          <Route
            index
            path="/get-subject-wise-grading-for-all-classes-of-selected-cluster"
            element={<SubjectWiseGradingForAllClassesOfSelectedCluster />}
          />
          <Route
            index
            path="/get-subject-wise-grading-for-all-classes-of-selected-school"
            element={
              <SubjectWiseGradingForAllClassesOfSelectedClusterAndSchool />
            }
          />
          <Route
            index
            path="/get-subject-wise-grading-for-all-school"
            element={<SubjectWiseGradingForAllSchool />}
          />
        </Route>
        <Route caseSensitive path="/login" element={<Login />} />
      </DomRoutes>
    </Suspense>
  );
}
