import {
  Button,
  CardContent,
  Grid,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Divider,
  FormControl,
  Menu,
  MenuItem,
  Paper,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axiosInstance from "../../Api/axios.instance";
import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import QuizIcon from "@mui/icons-material/Quiz";
import MuiAlert from "@mui/material/Alert";
import { LoadingButton } from "@mui/lab";
import { isArray } from "lodash";
import { MENU_ITEMS } from "./AddUserRoles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AddRolesList() {
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [openPages, setOpenPages] = useState(false);
  const [noPermission, setNoPermission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [menus, setMenus] = useState([]);
  const [roleName, setRoleName] = useState("");
  const [accesLevel, setAccesLevel] = useState("");
  const [roles, setRoles] = useState([]);
  const [selectedrole, setSelectedRole] = useState({});
  //   const [selectedRolePages, setSelectedRolePages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);

  useEffect(() => {
    _fetchRoles();
  }, []);

  const _fetchRoles = async () => {
    try {
      const { data, status } = await axiosInstance.get("roles");
      console.log("Roles", data.data);
      if (status === 200) {
        setRoles(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClick = (role) => (event) => {
    // setIsEdit(false);
    console.log("event", event);
    setAnchorEl(event.currentTarget);
    setSelectedRole(role);
    const parsePermissions = JSON.parse(role.permissions);
    const pagesArr = parsePermissions.pages;
    console.log("MENU_ITEMS : ", pagesArr.find(item => Array.isArray(item?.Reports) === true));
    MENU_ITEMS.forEach((menu) => {
      const existed = pagesArr.find((item) => item.title === menu.title);
      if(!existed){pagesArr.push(menu)}
    })
    MENU_ITEMS[24].Reports.forEach((menu) => {
      const reportsArray = pagesArr.find(item => Array.isArray(item?.Reports) === true);
      const existed = reportsArray.Reports.find((item) => item.title === menu.title);
      if(!existed){pagesArr.find(item => Array.isArray(item?.Reports) === true).Reports.push(menu)}
    })
    // const taskFilter = pagesArr.find((item) => item.title === task.title);
    // if (taskFilter)
    const parsedAL = parsePermissions.accessLevel;
    console.log("pagesArr", pagesArr);
    console.log("parsedAL", parsedAL);
    setMenus(pagesArr);
    setAccesLevel(parsedAL);
    setRoleName(role.RoleName);
  };
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };
  const _onUpdate = async (e) => {
    e.preventDefault();
    const formData = {
      roleName: roleName,
      permission: {
        accessLevel: accesLevel,
        pages: menus,
      },
    };
    // console.log("Updated Data", formData);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/roles/${selectedrole.RoleId}`,
        formData
      );
      console.log("Response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchRoles();
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpen(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };
  const _onEdit = () => {
    setOpen(true);
    handleAnchorClose();
  };
  const handleOpen = () => {
    // _resetForm();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenPages = (role) => () => {
    setOpenPages(true);
    const pagesArr = JSON.parse(role.permissions).pages;
    console.log("pagesArr", pagesArr);
    setRoleName(role.RoleName);
    setMenus(pagesArr);
  };
  const handleClosePages = () => {
    setOpenPages(false);
  };
  const handleMenusChange = (e, key, isReport) => {
    const { name, value } = e?.target;
    const newMenus = [...menus];
    console.log(isReport);
    if (isReport === true) {
      newMenus[newMenus.length - 1]["Reports"][key][name] = e.target.checked;
      setMenus(newMenus);
    } else {
      newMenus[key][name] = e.target.checked;
      setMenus(newMenus);
    }
    console.log(
      newMenus,
      "BRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR"
    );
  };
  const handleStatusChange = (role) => async (e) => {
    setStatus(e.target.value);
    try {
      setLoading((prevState) => !prevState);
      const { data, status } = await axiosInstance.put(
        `/roles/activate/${role.RoleId}`,
        { status: e.target.value }
      );
      console.log("Response", data.data);
      if (status === 200) {
        setLoading((prevState) => !prevState);
        _fetchRoles();
        setSnackMessage(data.message);
        setSnackBar(true);
        setOpen(false);
      }
    } catch (error) {
      setLoading((prevState) => !prevState);
      console.log(error);
    }
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
  };

  const handleAccessLevelChange = (e) => {
    setAccesLevel(e.target.value);
  };

  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackBar(false);
  };

  function renderList(menu, i) {
    return (
      <TableRow
        key={String(i)}
        sx={{
          "&:last-child td, &:last-child th": {
            border: 0,
          },
        }}
      >
        <TableCell component="th" scope="row">
          {menu.title}
        </TableCell>
        <TableCell align="left">
          {menu.create && " CREATE - "}
          {menu.read && " READ - "}
          {menu.update && " UPDATE - "}
          {menu.delete && " DELETE"}
          {!menu.create && !menu.read && !menu.update && !menu.delete
            ? "No Permission"
            : ""}
        </TableCell>
      </TableRow>
    );
  }

  function renderGrid(menu, index, isReport) {
    return (
      <Grid container spacing={2} key={index}>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography variant="h6" component="h4">
                {menu.title}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                value={menu.create}
                name="create"
                control={<Checkbox checked={menu.create} />}
                label="CREATE"
                labelPlacement="CREATE"
                onChange={(e) => handleMenusChange(e, index, isReport)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                value={menu.read}
                name="read"
                control={<Checkbox checked={menu.read} />}
                label="READ"
                labelPlacement="READ"
                onChange={(e) => handleMenusChange(e, index, isReport)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                value={menu.update}
                name="update"
                control={<Checkbox checked={menu.update} />}
                label="UPDATE"
                labelPlacement="UPDATE"
                onChange={(e) => handleMenusChange(e, index, isReport)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControlLabel
                value={menu.delete}
                name="delete"
                control={<Checkbox checked={menu.delete} />}
                label="DELETE"
                labelPlacement="DELETE"
                onChange={(e) => handleMenusChange(e, index, isReport)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Grid container spacing={3} alignItems="center" sx={{ mb: 4 }}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" component="h1">
            Roles List
          </Typography>
        </Grid>
        <Grid item xs={12} md={4}>
          {/*
          <FormControl fullWidth>
            <Button variant="contained" color="primary" onClick={handleOpen}>
              Add Variable
            </Button>
          </FormControl>
        */}
        </Grid>
        <Grid item xs={12} md={12}>
          <Divider sx={{ mt: 1, mb: 2 }} />
        </Grid>
        <Grid item xs={12} md={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Role Name</TableCell>
                  <TableCell align="center">Access Level</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell align="center">Pages</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {roles.map((role, index) => {
                  return (
                    <TableRow
                      key={String(index)}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {role.RoleName}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        {JSON.parse(role.permissions).accessLevel}
                      </TableCell>
                      <TableCell align="center" component="th" scope="row">
                        <TextField
                          id="standard-select-currency"
                          select
                          label="Status"
                          sx={{
                            display: "flex",
                            flex: 1,
                            width: "50%",
                            margin: "0 auto",
                          }}
                          //   error={
                          //     formik.touched.Gender &&
                          //     Boolean(formik.errors.Gender)
                          //   }
                          //   helperText={
                          //     formik.touched.Gender && formik.errors.Gender
                          //   }
                          onChange={handleStatusChange(role)}
                          value={role.status}
                          variant="outlined"
                          size="small"
                          name="status"
                          defaultValue="Select Gender"
                        >
                          <MenuItem key={"0"} value={true}>
                            Active
                          </MenuItem>
                          <MenuItem key={"1"} value={false}>
                            In Active
                          </MenuItem>
                        </TextField>
                      </TableCell>
                      <TableCell align="center">
                        <Button color="primary" onClick={handleOpenPages(role)}>
                          pages
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={handleClick(role)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openAnchor}
            onClose={handleAnchorClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={_onEdit}>Edit</MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Update Role</DialogTitle>
        <DialogContent>
          <Box onSubmit={_onUpdate} component="form">
            <Box flexGrow={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <Divider sx={{ mt: 1, mb: 2 }} />
                </Grid>
                <Grid item xs={12} md={6} sx={{ mb: 2 }}>
                  <TextField
                    label="Role Name"
                    variant="outlined"
                    size="small"
                    sx={{ display: "flex", flex: 1 }}
                    name="roleName"
                    value={roleName}
                    onChange={handleRoleNameChange}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Access Level"
                    sx={{ display: "flex", flex: 1 }}
                    // value={formik.values.SchoolID}
                    variant="outlined"
                    name="accesLevel"
                    value={accesLevel}
                    required
                    onChange={handleAccessLevelChange}
                    // error={formik.touched.SchoolID && Boolean(formik.errors.SchoolID)}
                    // helperText={formik.touched.SchoolID && formik.errors.SchoolID}
                    size="small"
                  >
                    <MenuItem value={"ADMIN"}>ADMIN</MenuItem>
                    <MenuItem value={"CLUSTER"}>CLUSTER</MenuItem>
                    <MenuItem value={"SCHOOL"}>SCHOOL</MenuItem>
                    <MenuItem value={"STAFF"}>STAFF</MenuItem>
                    <MenuItem value={"TEACHER"}>TEACHER</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
              {menus.map((menu, index) => {
                return !menu.Reports
                  ? renderGrid(menu, index, false)
                  : menu.Reports.map((report, i) => {
                      return renderGrid(report, i, true);
                    });
                // <Grid container spacing={2} key={index}>
                //   <Grid item xs={12} md={12}>
                //     <Grid container spacing={2}>
                //       <Grid item xs={12} md={12}>
                //         <Typography variant="h6" component="h4">
                //           {menu.title}
                //         </Typography>
                //       </Grid>
                //       <Grid item xs={12} md={3}>
                //         <FormControlLabel
                //           value={menu.create}
                //           name="create"
                //           control={<Checkbox checked={menu.create} />}
                //           label="CREATE"
                //           labelPlacement="CREATE"
                //           onChange={(e) => handleMenusChange(e, index)}
                //         />
                //       </Grid>
                //       <Grid item xs={12} md={3}>
                //         <FormControlLabel
                //           value={menu.read}
                //           name="read"
                //           control={<Checkbox checked={menu.read} />}
                //           label="READ"
                //           labelPlacement="READ"
                //           onChange={(e) => handleMenusChange(e, index)}
                //         />
                //       </Grid>
                //       <Grid item xs={12} md={3}>
                //         <FormControlLabel
                //           value={menu.update}
                //           name="update"
                //           control={<Checkbox checked={menu.update} />}
                //           label="UPDATE"
                //           labelPlacement="UPDATE"
                //           onChange={(e) => handleMenusChange(e, index)}
                //         />
                //       </Grid>
                //       <Grid item xs={12} md={3}>
                //         <FormControlLabel
                //           value={menu.delete}
                //           name="delete"
                //           control={<Checkbox checked={menu.delete} />}
                //           label="DELETE"
                //           labelPlacement="DELETE"
                //           onChange={(e) => handleMenusChange(e, index)}
                //         />
                //       </Grid>
                //     </Grid>
                //   </Grid>
                //   <Grid item xs={12} md={12}>
                //     <Divider sx={{ mt: 1, mb: 2 }} />
                //   </Grid>
                // </Grid>
              })}
              <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                  <FormControl fullWidth>
                    <LoadingButton
                      loading={loading}
                      disabled={loading}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      Update Role
                    </LoadingButton>
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        maxWidth="md"
        open={openPages}
        onClose={handleClosePages}
      >
        <DialogTitle>{`User Role (${roleName})`}</DialogTitle>
        <DialogContent>
          <Box onSubmit={() => {}} component="form">
            <Box flexGrow={1}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Pages</TableCell>
                      <TableCell align="left">Permissions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {menus.map((menu, index) => {
                      return (
                        // <TableRow
                        //   key={String(index)}
                        //   sx={{
                        //     "&:last-child td, &:last-child th": {
                        //       border: 0,
                        //     },
                        //   }}
                        // >
                        //   <TableCell component="th" scope="row">
                        //     {menu.title}
                        //   </TableCell>
                        //   <TableCell align="left">
                        //     {menu.create && " CREATE - "}
                        //     {menu.read && " READ - "}
                        //     {menu.update && " UPDATE - "}
                        //     {menu.delete && " DELETE"}
                        //     {!menu.create &&
                        //     !menu.read &&
                        //     !menu.update &&
                        //     !menu.delete
                        //       ? "No Permission"
                        //       : ""}
                        //   </TableCell>
                        // </TableRow>
                        !menu.Reports
                          ? renderList(menu, index)
                          : menu.Reports.map((report, i) => {
                              return renderList(report, i);
                            })
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePages}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackbar}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
