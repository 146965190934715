import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { async } from "q";
import axiosInstance from "../../Api/axios.instance";
import useSession from "../../CustomHooks/useSession";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function UserList({ users }) {
  const { currentPage } = useSession();
  const [currentPageCRUD] = useState(currentPage[0] ? currentPage[0] : "");
  const [open, setOpen] = useState(false);
  const [snackOpen, setSnackOpen] = useState(false);
  const [snackError, setSnackError] = useState(false);
  const [userData, setUserData] = useState({});
  const [snackMessage, setSnackMessage] = useState("");
  const [changePassword, setChangePassword] = useState({
    password: "",
    confirmPassword: "",
  });
  // const navigate = useNavigate();
  const handleChange = (e) => {
    setChangePassword((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  };
  const _onSubmit = async (e) => {
    e.preventDefault();
    console.log("changePassword", changePassword);
    try {
      if (changePassword.password !== changePassword.confirmPassword) {
        setSnackError(true);
        setSnackOpen(true);
        setSnackMessage("Password and Confirm Password are not matched!");
      } else {
        const { data, status } = await axiosInstance.put(
          `/users/change-password/${userData.id}`,
          { password: changePassword.password }
        );
        console.log("response", data.data);
        if (status === 200) {
          setSnackMessage(data.message);
          setSnackError(false);
          setSnackOpen(true);
          setOpen(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const _handleOnClick = (details) => (e) => {
    // navigate(`user-management/${details.id}`, {
    //   state: {
    //     userData: details,
    //   },
    // });
    console.log(details);
    setUserData(details);
    setOpen(true);
  };
  const handleScoreClose = () => {
    setOpen(false);
  };
  const snack_handleClose = () => {
    console.log("Snack bar Closed");
    setSnackOpen(false);
  };
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">User Name</TableCell>
              <TableCell align="left">Role</TableCell>
              <TableCell align="center">Access Level</TableCell>
              {/* <TableCell align="center">Designation</TableCell>
            <TableCell align="center">Cell # | Whatsapp #</TableCell>
            <TableCell align="center">Address</TableCell> */}
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentPageCRUD.read &&
              users.map((row, index) => (
                <TableRow
                  key={String(index)}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.username}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.Role.RoleName}
                  </TableCell>
                  <TableCell align="center">{row.AccessLevel}</TableCell>
                  {/* <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell>
              <TableCell align="center"></TableCell> */}
                  <TableCell align="right">
                    <Button variant="contained" onClick={_handleOnClick(row)}>
                      Change Password
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleScoreClose}>
        <DialogTitle>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" component="h2">
              Change Password
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box onSubmit={_onSubmit} component="form">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="New Password"
                  type="password"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="password"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label="Confirm Password"
                  type="password"
                  variant="outlined"
                  size="small"
                  sx={{ display: "flex", flex: 1 }}
                  name="confirmPassword"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  // loading={loading}
                  // disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  // onClick={formik.handleSubmit}
                >
                  Change Password
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleScoreClose}>Close</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={snack_handleClose}
      >
        <Alert
          onClose={snack_handleClose}
          severity={`${snackError ? "error" : "success"}`}
          sx={{ width: "100%" }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
